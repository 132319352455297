<template>
  <div>
    <sidebar-component></sidebar-component>
    <div class="relative md:ml-64 bg-blueGray-100">
      <navbar-component></navbar-component>
      <!-- Header -->
      <div class="relative bg-blue-600 md:pt-32 pb-32 pt-12">
        <div class="rounded-t mb-0 px-4 py-3 border-0">
          <div
            class="container mx-auto my-12 h-auto flex flex-wrap justify-between items-start text-sm p-8 pt-8 pb-4 box-border border-4 border-gray-400 bg-gray-100"
          >
            <a class="text-2xl text-center text-black font-bold"
              >Admin: Cadastro de Produto combo</a
            >
            <RouterLinkButton
              btnText=""
              color="sky"
              tipo="fas"
              icone="arrow-left"
              url="/admlistaProduto"
            />
            <div class="bg-green-500 w-full h-1"></div>
            <a class="text-black font-bold"
              >Empresa : {{ id_empresa }}-{{ razaoSocial }}</a
            >
            <div>
              <button
                class="bg-gray-400 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                v-on:click="toggleModal()"
              >
                Escolha a Empresa que pertence o Produto !
              </button>
              <div
                v-if="showModal"
                class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
              >
                <div class="relative w-auto my-6 mx-auto max-w-3xl">
                  <!--content-->
                  <div
                    class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                  >
                    <!--header-->
                    <div
                      class="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t"
                    >
                      <h3 class="text-3xl font-semibold">Empresa</h3>
                      <button
                        class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        v-on:click="toggleModal()"
                      >
                        <span
                          class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"
                        >
                          ×
                        </span>
                      </button>
                    </div>
                    <!--body-->
                    <div class="relative p-6 flex-auto">
                      <div class="flex mt-4 mb-8">
                        <div class="w-full md:w-1/2 px-3">
                          <TextInput
                            label="Digitar o nome da Empresa"
                            placeholder="Pesquisar..."
                            v-model:input="searchCompany"
                            inputType="text"
                          />
                        </div>
                        <div class="w-full md:w-1/2 px-3">
                          <form
                            @submit.prevent="onSubmit"
                            @click="getCompanyList"
                          >
                            <LoadingButton
                              :isLoading="isLoading"
                              class="block w-full bg-sky-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-gray-700 motion-safe:hover:scale-110 transition ease-in-out"
                            >
                              Pesquisar
                            </LoadingButton>
                          </form>
                        </div>
                        <div class="mx-auto py-4">
                          <table class="table-auto text-xs">
                            <thead>
                              <tr class="text-gray-600 bg-gray-300">
                                <th class="border px-4 py-2">Id</th>
                                <th class="border px-4 py-2">Empresa</th>
                                <th class="border px-4 py-2"></th>
                                <th class="border px-4 py-2">Ação</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                class="text-gray-500 bg-gray-100"
                                v-for="listCompany in listCompanies"
                                :key="listCompany"
                              >
                                <td>{{ listCompany.id }}</td>
                                <td>{{ listCompany.razaoSocial }}</td>
                                <td>{{ listCompany.nomeFantasia }}</td>
                                <td>
                                  <div
                                    class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                                  >
                                    <SubmitFormButton
                                      btnText="Escolha"
                                      @click="selectCompany(listCompany)"
                                      v-on:click="toggleModal()"
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="flex items-center justify-center p-2">
                            <v-pagination
                              v-model="pageCompanies"
                              :pages="pageCountCompanies"
                              :range-size="1"
                              active-color="#337aff"
                              @update:modelValue="listCompanies"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <vue-snotify />
                    <!--footer-->
                    <div
                      class="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b"
                    >
                      <button
                        class="text-green-500 bg-transparent border border-solid border-green-500 hover:bg-green-500 hover:text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        v-on:click="toggleModal()"
                      >
                        Voltar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="showModal"
                class="opacity-25 fixed inset-0 z-40 bg-black"
              ></div>
            </div>
            <div class="bg-green-500 w-full h-1"></div>
            <div class="mx-auto py-4">
              <div class="flex flex-wrap font-bold text-gray-100">
                <div class="w-full mt-4">
                  <div class="text-gray-900 text-xl">
                    Código Produto : {{ id_estoque }}
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-full px-3">
              <div class="bg-green-500 w-full h-1"></div>
              <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div
                  class="w-full border px-4 py-2 rounded focus:border-blue-500 focus:shadow-outline outline-none"
                >
                  <div class="w-full md:w-full px-6">
                    <span class="inline-grid grid-cols-3 gap-4">
                      <span>
                        <div class="w-full text-gray-900 md:w-full px-6">
                          <TextInput
                            label="Código de barra"
                            placeholder="Digitar o Código de barra !"
                            v-model:input="codigo_barra"
                            inputType="text"
                            :error="
                              errors.codigo_barra ? errors.codigo_barra[0] : ''
                            "
                          />
                        </div>
                      </span>
                      <span
                        ><div class="w-full text-gray-900 md:w-full px-6">
                          <TextInput
                            label="Nome do produto"
                            placeholder="Digitar o nome do produto !"
                            v-model:input="nome_produto"
                            inputType="text"
                            :error="
                              errors.nome_produto ? errors.nome_produto[0] : ''
                            "
                          />
                          {{ nome_produto }}
                        </div>
                      </span>
                      <span>
                        <div class="w-full md:w-full px-6">
                          <label
                            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            for="grid-state"
                          >
                            Categoria
                          </label>
                          <div class="relative">
                            <select
                              v-model="categoria"
                              class="text-center block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id="grid-state"
                            >
                              <option>VENDA</option>
                              <option>EXTINTOR</option>
                              <option>ESCRITÓRIO</option>
                            </select>
                          </div>
                        </div>
                      </span>
                      <span>
                        <div class="w-full md:w-full px-6">
                          <label
                            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            for="grid-state"
                          >
                            Classe / Unidade
                          </label>
                          <div class="relative">
                            <select
                              v-model="unidade"
                              class="text-center block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id="grid-state"
                            >
                              <option>UN</option>
                              <option>A</option>
                              <option>B</option>
                              <option>C</option>
                              <option>D</option>
                            </select>
                          </div>
                        </div>
                      </span>
                      <span>
                        <div class="w-full md:w-full px-6">
                          <TextInput
                            label="Estoque"
                            placeholder="Digitar a qtd de estoque !"
                            v-model:input="estoque"
                            inputType="hidden"
                          />
                          {{ estoque }}
                        </div>
                      </span>
                      <span>
                        <div class="w-full text-gray-900 md:w-full px-6">
                          <TextInput
                            label="Estoque mínimo"
                            placeholder="Digitar a qtd de estoque mínimo !"
                            v-model:input="estoque_minimo"
                            inputType="text"
                            :error="
                              errors.estoque_minimo
                                ? errors.estoque_minimo[0]
                                : ''
                            "
                          />
                        </div>
                      </span>
                      <span>
                        <div class="w-full text-gray-900 md:w-full px-6">
                          <TextInputMoeda
                            label="Preço Venda"
                            placeholder="Digitar o preço do produto !"
                            v-model:input="preco_venda"
                            inputType="text"
                            :error="
                              errors.preco_venda ? errors.preco_venda[0] : ''
                            "
                          />
                        </div>
                      </span>
                      <span>
                        <div class="w-full text-gray-900 md:w-full px-6">
                          <TextInput
                            label="ML"
                            placeholder="Digitar o Total ML !"
                            v-model:input="estoque_ml"
                            inputType="text"
                          />
                        </div>
                      </span>
                      <span>
                        <div class="w-full text-gray-900 md:w-full px-6">
                          <TextInput
                            label="Numéro contábil"
                            placeholder="Digitar o Numéro contábil !"
                            v-model:input="cod_conta"
                            inputType="text"
                          />
                        </div>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="flex flex-wrap mt-8 mb-6">
                <form @submit.prevent="onSubmit" @click="registercombo">
                  <LoadingButton
                    :isLoading="isLoading"
                    v-on:click="toggleComboStock()"
                    class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                  >
                    Salvar
                  </LoadingButton>
                </form>
                <vue-snotify />
              </div>
            </div>
            <div v-if="showComboStock" class="w-full md:w-full px-3">
              <div
                class="container mx-auto my-12 h-auto flex flex-wrap justify-between items-start text-sm p-8 pt-8 pb-4 box-border border-4 border-gray-400 bg-gray-100"
              >
                <div>
                  <button
                    class="bg-gray-400 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    v-on:click="toggleModalstock()"
                  >
                    Escolha os produtos para formar combo !
                  </button>
                  <div
                    v-if="showModalstock"
                    class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
                  >
                    <div class="relative w-auto my-6 mx-auto max-w-3xl">
                      <!--content-->
                      <div
                        class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                      >
                        <!--header-->
                        <div
                          class="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t"
                        >
                          <h3 class="text-3xl font-semibold">
                            Produto para o combo
                          </h3>
                          <button
                            class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            v-on:click="toggleModalstock()"
                          >
                            <span
                              class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"
                            >
                              ×
                            </span>
                          </button>
                        </div>
                        <!--body-->
                        <div class="relative p-6 flex-auto">
                          <div class="flex mt-4 mb-8">
                            <div class="w-full md:w-1/2 px-3">
                              <TextInput
                                label="Digitar o código de barra"
                                placeholder="Pesquisar..."
                                v-model:input="searchstock"
                                inputType="text"
                              />
                            </div>
                            <div class="w-full md:w-1/2 px-3">
                              <form
                                @submit.prevent="onSubmit"
                                @click="getStockList"
                              >
                                <LoadingButton
                                  :isLoading="isLoading"
                                  class="block w-full bg-sky-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-gray-700 motion-safe:hover:scale-110 transition ease-in-out"
                                >
                                  Pesquisar
                                </LoadingButton>
                              </form>
                            </div>
                            <div class="mx-auto py-4">
                              <table class="table-auto text-xs">
                                <thead>
                                  <tr class="text-gray-600 bg-gray-300">
                                    <th class="border px-4 py-2">Cód</th>
                                    <th class="border px-4 py-2">Cód barra</th>
                                    <th class="border px-4 py-2">Produto</th>
                                    <th class="border px-4 py-2">Ação</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    class="text-gray-500 bg-gray-100"
                                    v-for="liststock in liststocks"
                                    :key="liststock"
                                  >
                                    <td>{{ liststock.id }}</td>
                                    <td>{{ liststock.codigo_barra }}</td>
                                    <td>{{ liststock.nome_produto }}</td>
                                    <td>
                                      <div
                                        class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                                      >
                                        <SubmitFormButton
                                          btnText="Escolha"
                                          @click="selectStock(liststock)"
                                          v-on:click="toggleModalstock()"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div class="flex items-center justify-center p-2">
                                <v-pagination
                                  v-model="pageCompanies"
                                  :pages="pageCountCompanies"
                                  :range-size="1"
                                  active-color="#337aff"
                                  @update:modelValue="listCompanies"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <vue-snotify />
                        <!--footer-->
                        <div
                          class="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b"
                        >
                          <button
                            class="text-green-500 bg-transparent border border-solid border-green-500 hover:bg-green-500 hover:text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            v-on:click="toggleModalstock()"
                          >
                            Voltar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="showModalstock"
                    class="opacity-25 fixed inset-0 z-40 bg-black"
                  ></div>
                </div>
                <span class="inline-grid grid-cols-3 gap-4">
                  <span>
                    <a class="text-black font-bold"
                      >Combo : {{ id_produto }}-{{ codigo_barra_combo }}-{{
                        nome_produto
                      }}</a
                    >
                  </span>
                  <span>
                    <div class="w-full text-gray-900 md:w-full px-6">
                      <TextInput
                        label="QUANTIDADE"
                        placeholder="Digitar a quantidade !"
                        v-model:input="estoque_combo"
                        inputType="text"
                        :error="
                          errors.estoque_combo ? errors.estoque_combo[0] : ''
                        "
                      />
                    </div>
                  </span>
                  <span>
                    <div class="w-full text-gray-900 md:w-full px-6">
                      <TextInput
                        label="ML"
                        placeholder="Digitar o ML !"
                        v-model:input="estoque_ml_combo"
                        inputType="text"
                        :error="
                          errors.estoque_ml_combo
                            ? errors.estoque_ml_combo[0]
                            : ''
                        "
                      />
                    </div>
                  </span>
                  <span>
                    <div class="w-full text-gray-700 md:w-auto px-3">
                      <form
                        @submit.prevent="onSubmitVenda"
                        @click="registeritemcombo"
                      >
                        <LoadingButton
                          :isLoading="isLoadingVenda"
                          class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                        >
                          Incluir
                        </LoadingButton>
                      </form>
                    </div>
                  </span>
                </span>
                <div class="bg-green-500 w-full h-1"></div>
                <table class="table-auto text-xs">
                  <thead>
                    <tr class="text-gray-600 bg-gray-300">
                      <th class="border px-4 py-2">Código</th>
                      <th class="border px-4 py-2">Código barras</th>
                      <th class="border px-4 py-2">Quantidade</th>
                      <th class="border px-4 py-2">Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-if="listSolicitItemsvendas"
                      class="text-gray-500 bg-gray-100"
                      v-for="listSolicitItemsvenda in listSolicitItemsvendas"
                      :key="listSolicitItemsvendas"
                    >
                      <td>
                        {{ listSolicitItemsvenda.id }}
                      </td>
                      <td>
                        {{ listSolicitItemsvenda.codigo_barra }}
                      </td>
                      <td>
                        {{ listSolicitItemsvenda.quantidade }}
                      </td>
                      <td>
                        <SubmitFormButton
                          btnText="Deletar"
                          class="underline text-red-500 hover:text-red-600"
                          @click="deleteItemSolitacao(listSolicitItem.id)"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ref } from "vue";
import NavbarComponent from "@/components/Navbar.vue";
import SidebarComponent from "@/components/Sidebar.vue";
import AdmMenuComponent from "@/components/parcial/adm/AdmMenu.vue";
import RouterLinkButton from "@/components/global/RouterLinkButton.vue";
import TextInput from "@/components/global/TextInput.vue";
import TextInputCep from "@/components/global/TextInputCep.vue";
import TextInputCelular from "@/components/global/TextInputCelular.vue";
import TextInputTelefone from "@/components/global/TextInputTelefone.vue";
import TextInputRg from "@/components/global/TextInputRg.vue";
import TextInputCpf from "@/components/global/TextInputCpf.vue";
import TextInputDate from "@/components/global/TextInputDate.vue";
import TextInputMoeda from "@/components/global/TextInputMoeda.vue";
import LoadingButton from "@/components/global/LoadingButton.vue";
import SubmitFormButton from "@/components/global/SubmitFormButton.vue";

import { useRoute, useRouter } from "vue-router";
import { useUserStore } from "@/store/user-store";

export default {
  components: {
    NavbarComponent,
    SidebarComponent,
    AdmMenuComponent,
    RouterLinkButton,
    LoadingButton,
    TextInput,
    TextInputMoeda,
    SubmitFormButton,
    TextInputTelefone,
    TextInputCelular,
    TextInputRg,
    TextInputCpf,
    TextInputDate,
    TextInputCep,
  },
  name: "regular-modal",
  data() {
    const userStore = useUserStore();
    const route = useRoute();
    const router = useRouter();

    const url = process.env.VUE_APP_API_URL;
    let hover = ref(false);
    let showModal = ref(false);
    let showModalstock = ref(false);
    let showComboStock = ref(false);
    let searchCompany = ref(null);
    let listCompanies = ref(null);
    let pageCompanies = ref(1);
    let pageCountCompanies = ref(null);
    let searchstock = ref(null);
    let liststocks = ref(null);
    let pagestocks = ref(1);
    let pageCountstocks = ref(null);
    let errors = ref([]);
    let error = ref([]);
    return {
      date: new Date().getFullYear(),
      useradministrador: userStore.administrador,
      userid_company: userStore.id_empresa,
      id_responsavel: userStore.id,
      route: route,
      router: router,
      url_api: url,
      hover: hover,
      showModal: showModal,
      showModalstock: showModalstock,
      showComboStock: showComboStock,
      searchCompany: searchCompany,
      listCompanies: listCompanies,
      pageCompanies: pageCompanies,
      pageCountCompanies: pageCountCompanies,
      searchstock: searchstock,
      liststocks: liststocks,
      pagestocks: pagestocks,
      pageCountstocks: pageCountstocks,
      errors: errors,
      error: error,
      isLoading: ref(false),
      id: ref(null),
      id_razaoSocial: ref(null),
      razaoSocial: ref(null),
      id_fornecedor: ref(null),
      codigo_barra: ref(null),
      nome_produto: ref(null),
      categoria: "VENDA",
      unidade: "UN",
      estoque: 1,
      estoque_ml: 0,
      estoque_minimo: 1,
      preco_venda: 0,
      ca: ref(null),
      cod_conta: 0,
      statuscombo: "S",
      id_estoque: ref(null),
      codigo_barra_combo: ref(null),
      estoque_combo: ref(null),
      estoque_ml_combo: ref(null),
      preco_venda_combo: ref(null),
    };
  },
  methods: {
    getCompanyList() {
      // Make a GET request to the API
      axios
        .get(
          this.url_api +
            "/api/companysearch/" +
            this.searchCompany +
            "?page=" +
            this.pageCompanies
        )
        .then((response) => {
          // Handle the successful response
          // console.log(response.data);
          this.pageCountCompanies = response.data.page_count1;
          this.listCompanies = response.data.paginate1.data;
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error fetching data:", error);
          this.$snotify.error("Verifique novamente !");
        });
    },
    getStockList() {
      // Make a GET request to the API
      axios
        .get(
          this.url_api +
            "/api/stocksearchCB/" +
            this.searchstock +
            "?page=" +
            this.pagestocks
        )
        .then((response) => {
          //console.log(response.data);
          this.pageCountstocks = response.data.page_count;
          this.liststocks = response.data.paginate.data;
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error getStockList:", error);
        });
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    toggleModalstock() {
      this.showModalstock = !this.showModalstock;
    },
    toggleComboStock() {
      this.showComboStock = !this.showComboStock;
    },
    selectCompany(event) {
      this.id_empresa = event.id;
      this.razaoSocial = event.razaoSocial;
      this.$snotify.info("Escolheu a : " + this.razaoSocial);
    },
    selectStock(event) {
      this.id_produto = event.id;
      this.codigo_barra_combo = event.codigo_barra;
      this.nome_produto = event.nome_produto;
      this.$snotify.info("Escolheu a : " + this.nome_produto);
    },
    onSubmit() {
      this.isLoading = true;
      setTimeout(() => (this.isLoading = false), 1000);
    },
    registercombo() {
      this.error = [];
      let data = new FormData();
      data.append("id_responsavel", this.id_responsavel || "");
      data.append("id_empresa", this.id_empresa || "");
      data.append("codigo_barra", this.codigo_barra || "");
      data.append("nome_produto", this.nome_produto || "");
      data.append("categoria", this.categoria || "");
      data.append("unidade", this.unidade || "");
      data.append("estoque", this.estoque || "");
      data.append("estoque_ml", this.estoque_ml || "");
      data.append("estoque_minimo", this.estoque_minimo || "");
      data.append("preco_venda", this.preco_venda || "");
      data.append("cod_conta", this.cod_conta || "");
      data.append("statuscombo", this.statuscombo || "");
      // console.log(data);

      axios
        .post(this.url_api + "/api/stockcombo", {
          id_responsavel: this.id_responsavel,
          id_empresa: this.id_empresa,
          codigo_barra: this.codigo_barra,
          nome_produto: this.nome_produto,
          categoria: this.categoria,
          unidade: this.unidade,
          estoque: this.estoque,
          estoque_ml: this.estoque_ml,
          estoque_minimo: this.estoque_minimo,
          preco_venda: this.preco_venda,
          cod_conta: this.cod_conta,
          statuscombo: this.statuscombo,
        })
        .then((stock) => {
          this.id_estoque = stock.id;
          this.errors = "";
          this.$snotify.success("Salvo com sucesso !");
        })
        .catch((error) => {
          // Handle any errors
          this.showComboStock = false;
          //console.log(error);
          this.errors = error.response.data.errors;
          this.$snotify.error(this.errors);
        });
    },
    registeritemcombo() {
      this.error = [];
      let data = new FormData();
      data.append("id_produto", this.id_produto || "");
      data.append("codigo_barra_combo", this.codigo_barra || "");
      data.append("estoque_combo", this.estoque_combo || "");
      data.append("estoque_ml_combo", this.estoque_ml_combo || "");
      data.append("preco_venda_combo", this.preco_venda || "");
      data.append("estoque_ml", this.estoque_ml || "");
      data.append("preco_venda", this.preco_venda || "");

      // console.log(data);

      axios
        .post(this.url_api + "/api/stockcomboitem", {
          id_estoque: this.id_estoque,
          codigo_barra_combo: this.codigo_barra_combo,
          estoque_combo: this.estoque_combo,
          estoque_ml_combo: this.estoque_ml_combo,
          preco_venda_combo: this.preco_venda_combo,
          estoque_ml: this.estoque_ml,
          preco_venda: this.preco_venda,
        })
        .then(() => {
          this.errors = "";
          this.$snotify.success("Salvo com sucesso !");
        })
        .catch((error) => {
          // Handle any errors
          this.errors = error.response.data.errors;
          this.$snotify.error("Verificar digitação !");
        });
    },
    onPageChange() {
      this.register();
      this.getCompanyList();
    },
  },
  mounted() {},
};
</script>
